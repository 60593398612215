/*!
 * Bootstrap v3.0.0
 *
 * Copyright 2013 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world by @mdo and @fat.
 */


// Core variables and mixins
@import "fonts.less";
@import "bootstrap/variables.less";
@import "bootstrap/mixins.less";

// Reset
@import "bootstrap/normalize.less";
// @import "bootstrap/print.less";

// Core CSS
// @import "bootstrap/scaffolding.less";
@import "bootstrap/type.less";
// @import "bootstrap/code.less";
// @import "bootstrap/grid.less";
// @import "bootstrap/tables.less";
@import "bootstrap/forms.less";
@import "bootstrap/buttons.less";

// Font Awesome
@import "font-awesome/font-awesome.less";

// Components
// @import "bootstrap/component-animations.less";
// @import "bootstrap/dropdowns.less";
// @import "bootstrap/button-groups.less";
@import "bootstrap/input-groups.less";
// @import "bootstrap/navs.less";
// @import "bootstrap/navbar.less";
// @import "bootstrap/breadcrumbs.less";
@import "bootstrap/pagination.less";
// @import "bootstrap/pager.less";
// @import "bootstrap/labels.less";
// @import "bootstrap/badges.less";
// @import "bootstrap/jumbotron.less";
// @import "bootstrap/thumbnails.less";
// @import "bootstrap/alerts.less";
// @import "bootstrap/progress-bars.less";
// @import "bootstrap/media.less";
// @import "bootstrap/list-group.less";
// @import "bootstrap/panels.less";
// @import "bootstrap/wells.less";
// @import "bootstrap/close.less";

// Components w/ JavaScript
@import "bootstrap/modals.less";
@import "bootstrap/tooltip.less";
@import "bootstrap/popovers.less";
// @import "bootstrap/carousel.less";

// Utility classes
@import "bootstrap/utilities.less";
// @import "bootstrap/responsive-utilities.less";

@media screen and (max-width : 820px) {
    table.login *{
        max-width:400px;
    }
}

@media screen and (max-width : 722px) {
    table.login *{
        max-width:300px;
    }
}

@media screen and (max-width : 622px) {
    table.login{
        margin-top:30px;
    }
    table.login *{
        max-width:200px;
    }
}

@media screen and (max-width : 522px) {
    table.login tbody tr td.login-logo{
        display:none;
    }
    table.login tbody tr td.login-content{
        padding:20px;
    }
    table.login *{
        max-width:300px;
    }
    table.login tbody tr td.login-content input{
        padding:5px;
    }
}

@media screen and (max-width : 430px) {
    table.login *{
        max-width:270px;
    }
}
