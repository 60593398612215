@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot');
  src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'),
       url('../fonts/fontawesome-webfont.woff') format('woff'),
       url('../fonts/fontawesome-webfont.ttf') format('truetype'),
       url('../fonts/fontawesome-webfont.svg') format('svg');

  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/RobotoCondensed-Light-webfont.eot');
    src: url('../fonts/RobotoCondensed-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/RobotoCondensed-Light-webfont.woff') format('woff'),
         url('../fonts/RobotoCondensed-Light-webfont.ttf') format('truetype'),
         url('../fonts/RobotoCondensed-Light-webfont.svg#roboto_condensedregular') format('svg');

    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/RobotoCondensed-Regular-webfont.eot');
    src: url('../fonts/RobotoCondensed-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/RobotoCondensed-Regular-webfont.woff') format('woff'),
         url('../fonts/RobotoCondensed-Regular-webfont.ttf') format('truetype'),
         url('../fonts/RobotoCondensed-Regular-webfont.svg#roboto_condensedregular') format('svg');

    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Dax';
    src: url('../fonts/dax-webfont.eot');
    src: url('../fonts/dax-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/dax-webfont.woff') format('woff'),
         url('../fonts/dax-webfont.ttf') format('truetype');

    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Dax';
    src: url('../fonts/dax-italic-webfont.eot');
    src: url('../fonts/dax-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/dax-italic-webfont.woff') format('woff');

    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Dax';
    src: url('../fonts/management_place/dax-bold-webfont.eot');
    src: url('../fonts/management_place/dax-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/management_place/dax-bold-webfont.woff') format('woff'),
         url('../fonts/management_place/dax-bold-webfont.ttf') format('truetype');

    font-weight: bold;
    font-style: normal;
}
